import React, { Component } from "react"
import { renderSVG } from "."
import Footer from "../components/base/Footer"
import SEO from "../components/base/seo"
import Hero from "../components/marketing/Hero"
import {
  CogIcon,
  PresentationChartBarIcon,
  PrinterIcon,
  PuzzlePieceIcon,
  ServerStackIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/solid"
import {
  ActionSection,
  Container,
  IconList,
  LeftContent,
  RightContent,
} from "blixify-ui-web/lib"
import CustomHeader from "../components/base/CustomHeader"
import AboutDashboard from "../images/about_dashboard.png"
import RnD from "../images/about_rd.png"
import { Link } from "gatsby"

const spaceContentList = [
  {
    name: "Interactive/Dynamic Database",
    content:
      "A spreadsheet-like database that allows users to seamlessly add/edit row/columns with roles access. Form sharing capability to input data without login.",
    icon: <ServerStackIcon className="w-6 h-6" />,
  },
  {
    name: "Graph Visualisation",
    content:
      "Draw insightful graphs based on the data from our dynamic database.",
    icon: <PresentationChartBarIcon className="w-6 h-6" />,
  },
  {
    name: "Workflow Engine",
    content:
      "Perform automation based on triggers made on database like sending an email or creating a new item when a certain task is completed.",
    icon: <CogIcon className="w-6 h-6" />,
  },
  {
    name: "Third Party API Integration",
    content:
      "Open API for developers to build customization for data visualisation and IoT product integration.",
    icon: <PuzzlePieceIcon className="w-6 h-6" />,
  },
]

const researchContentList = [
  {
    name: "Renewable Energy",
    content:
      "We have explored bioplastic filament (51% palm oil kernel, 49% plastic) to use in some of our prototyping 3d printed projects",
    icon: <PrinterIcon className="w-6 h-6" />,
  },
  {
    name: "Deep Learning Algorithms",
    content:
      "Constant learning is always our philosophy, our team constantly explores the latest advancements in artificial neural networks to understand their practical applications for our clients.",
    icon: <ViewfinderCircleIcon className="w-6 h-6" />,
  },
]

export default class AboutUs extends Component {
  render() {
    return (
      <>
        <SEO
          title="Blixify - About Us"
          description="Blixify was established in Penang, Malaysia. We are a software house focusing on Full-Stack Web and Mobile App Development as well as Internet of Things (IoT) services."
        />
        <div className="relative bg-gray-50 overflow-hidden">
          <CustomHeader />
          {renderSVG()}
          <Hero title="About Us" />
        </div>
        <Container bgColor="bg-white" className="pb-10">
          <LeftContent
            className="my-12"
            title="About Us"
            contentList={[
              "Blixify was established in late 2020 and is based in Penang, Malaysia. We are a software house focusing on Full-Stack Web and Mobile App Development as well as Internet of Things (IoT) services.",
              "We build our own proprietary dashboard engine that serves as a versatile dashboard for our clients, allowing users to effortlessly read and update data, create interactive charts, and automate processes.",
              "By utilizing our dashboard engine, developers can focus on building client applications faster. Our engine simplifies the project cycle, making it up to five times faster. Some features of our dashboard engine include : ",
            ]}
            image={AboutDashboard}
            imgContain={true}
            callToAction={<IconList iconList={spaceContentList} />}
          />
          <RightContent
            className="my-12"
            title="R&D Efforts"
            image={RnD}
            imgContain={true}
            contentList={[
              "At Blixify, we strive to improve our technical know-how to deliver more values to our partners & clients. We are constantly learning to  keep ourselves updated with latest technologies & machine learning algorithms to deliver the best value to our clients",
            ]}
            callToAction={<IconList iconList={researchContentList} />}
          />
          <ActionSection
            title="Understand Our Services"
            buttonLink="/services"
            buttonText="Go to Services"
            linkType="gatsby"
            custom={Link}
          />
        </Container>
        <Footer />
      </>
    )
  }
}
